import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Box,
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import LanguagesMenu from "../Components/LanguagesMenu";
import NotificationsPopover from "../Components/NotificationsPopover";
import { useMicrospecDynamicUIContext } from "../GlobalProvider";
import { bgBlur } from "../Helpers/CssStyles";
import { isRTL } from "../Helpers/Utils";
import { useMicrospecThemeLanguageContext } from "../Themes";
import { drawerWidth } from "./Dashboard";

export const APPBAR_MAX_HEIGHT = "65px";

const DashboardAppbar = ({ data }) => {
  const menuId = "primary-account-menu";
  const handleDrawerToggle = data.handleDrawerToggle;
  const activeItem = data.item;

  const { cloudProvider } = useMicrospecDynamicUIContext();
  const { userProfile } = cloudProvider;
  const { localizationProvider } = useMicrospecThemeLanguageContext();

  const StyledAppBar = styled(AppBar)(({ theme }) => ({
    ...bgBlur({ color: theme.palette.primary.main, opacity: 0.8 }),
    boxShadow: "none",
  }));

  return (
    <StyledAppBar
      component="nav"
      position="fixed"
      sx={{
        height: APPBAR_MAX_HEIGHT,
        maxHeight: APPBAR_MAX_HEIGHT,
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: !isRTL() ? `${drawerWidth}px` : "0px" },
        mr: { sm: isRTL() ? `${drawerWidth}px` : "0px" },
      }}
      enableColorOnDark
    >
      <Toolbar sx={{ display: "flex", height: "inherit" }} disableGutters>
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ display: { sm: "none" }, mx: 1 }}
        >
          <MenuIcon color="white" sx={{ color: "white" }} />
        </IconButton>
        <Grid
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1.5,
            mx: 1.5,
          }}
        >
          <Typography variant="h4" noWrap component="div">
            {localizationProvider.translateByType(activeItem.name)}
          </Typography>
        </Grid>
        <Box sx={{ display: { md: "flex" }, mx: 2 }}>
          <LanguagesMenu />
          <NotificationsPopover />
          <Tooltip title={userProfile.attributes.displayName}>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              // onClick={handleProfileMenuOpen}
              color="inherit"
              sx={{ display: { xs: "none", sm: "none", md: "block" } }}
            >
              <Avatar
                alt={userProfile.attributes.displayName}
                src={userProfile.attributes.photoURL}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
};

export default DashboardAppbar;
