import {
  Box,
  Chip,
  Grid
} from "@mui/material";
import availableCourses from "./availableCourses";
import "./coursesStyle.css";

const LandingPageAvailableSection = ({handleOpenContactDialog}) => {
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 3,
        }}
      >
        {availableCourses.map((course, index) => (
          <Grid
            item
            xs={12}
            md={6}
            xl={4}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box id="card-some_id" class="card">
              <div class="card-image">
                <img src={course.img} alt="card" loading="eager" />
              </div>

              <div class="card-info">
                <div class="content">
                  <div class="author-image"></div>

                  <div class="something">
                    <h2 class="card-title">
                      {course.name}
                    </h2>
                    <span class="card-category">{course.secondary}</span>
                  </div>

                  <div class="card-coin-icon"></div>
                </div>

                <div class="prices">
                  {/* <div class="price">
                    <strong>{`مساق ${index + 1}`}</strong>
                  </div> */}

                  {/* <div class="price">
                    <strong>{`${course.lessons.length} دُروس`}</strong>
                  </div> */}

                  <div class="price">
                    <Chip
                      label={course.label}
                      variant="filled"
                      color={index === 0 ? "info" : "warning"}
                    />
                  </div>
                </div>
              </div>

              <div class="card-actions">
                <a
                  onClick={() => handleOpenContactDialog()}
                  className="btn btn-base btn-g-blue-veronica text-base"
                >
                  הצטרף עכשיו
                </a>
              </div>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default LandingPageAvailableSection;
