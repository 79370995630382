import { useEffect, useState } from "react";
import arrow from "./assets/icons/arrow.svg";
import closeIcon from "./assets/icons/close.svg";
import createIcon from "./assets/icons/create_icon.svg";
import loginIcon from "./assets/icons/login_icon.svg";
import manageIcon from "./assets/icons/manage_icon.svg";
import supportIcon from "./assets/images/support.png";
import ContactFormDialog from "./components/ContactFormDialog/ContactFormDialog";
import LandingPageAvailableSection from "./components/LandingPageCousresAvailableSection/LandingPageAvailableSection";
import siteIcon from "./components/LandingPageCousresAvailableSection/logo-only.png";
import "./style/css/main.css";

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

const LandingPage = () => {
  const [isContactDialogOpen, setContactDialogOpen] = useState();
  const [isNavbarVisible, setNavbarVisible] = useState(false);

  const handleCloseContactDialog = () => {
    setContactDialogOpen();
  };

  const handleOpenContactDialog = () => {
    setContactDialogOpen(true);
  };

  useEffect(() => {
    let resizeTimer;

    const handleResize = () => {
      document.body.classList.add("resize-animation-stopper");
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        document.body.classList.remove("resize-animation-stopper");
      }, 400);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      clearTimeout(resizeTimer);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="page-wrapper bg-dark">
        {/* header */}
        <div className="header-wrapper">
          <header className="header flex">
            <div className="container w-full flex-1 flex">
              <div className="header-content w-full flex-1 flex flex-col">
                <nav className="navbar flex items-center justify-between">
                  <div className="brand-and-toggler flex items-center justify-between w-full">
                    <a
                      className="navbar-brand flex items-center"
                      style={{
                        gap: "8px",
                      }}
                    >
                      <img
                        src={siteIcon}
                        style={{
                          width: "65px",
                          height: "65px",
                        }}
                      />
                      <span className="brand-text">
                        BitsBull
                        <span className="brand-text">.io</span>
                      </span>
                    </a>
                    {/* <button
                      type="button"
                      className="navbar-show-btn"
                      onClick={() => setNavbarVisible(true)}
                    >
                      <img src={menuIcon} alt="Menu Icon" />
                    </button> */}
                  </div>
                  <div
                    className={`navbar-list-wrapper flex items-center ${isNavbarVisible ? "show" : "hide"
                      }`}
                  >
                    <ul className="nav-list flex items-center">
                      <button
                        type="button"
                        className="navbar-hide-btn"
                        onClick={() => setNavbarVisible(false)}
                      >
                        <img src={closeIcon} alt="close" />
                      </button>
                      {/* <li className="nav-item">
                        <a className="nav-link text-base no-wrap">Buy / Sell</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link text-base no-wrap">Grow</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link text-base no-wrap">Market</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link text-base no-wrap">Business</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link text-base no-wrap">Support</a>
                      </li> */}
                    </ul>
                    <div className="sign-btns flex items-center">
                      {/* <a
                        href="/login?redirectTo=/app/guide"
                        className="btn text-base"
                        style={{
                          flex: 1,
                          maxWidth: "220px",
                          minWidth: "120px",
                        }}
                      >
                        تسجيل الدخول
                      </a> */}
                      {/* <a className="btn btn-g-blue-veronica text-base">
                        انضمام
                      </a> */}
                    </div>
                  </div>
                </nav>
                <div className="header-intro flex-1 flex flex-col flex-gap-4 items-center justify-center text-center">
                  <h1
                    style={{
                      lineHeight: "normal",
                    }}
                  >
                    תן למומחים שלנו להוביל אותך להצלחה בשוק הקריפטו!
                  </h1>
                  <a
                    className="btn btn-base btn-g-blue-veronica text-base"
                    onClick={handleOpenContactDialog}
                  >
                    צור קשר
                  </a>
                </div>
              </div>
            </div>
          </header>
          <div className="info flex items-center justify-center">
            <div className="container">
              <div className="info-content grid">
                <div className="info-item text-center">
                  <img src={loginIcon} alt="Login Icon" />
                  <h3 className="info-item-title">תצטרף אלינו</h3>
                  <p className="text-base text info-item-text">
                    ההצטרפות שלך אלינו תחסוך ממך את הכאב ראש ללמוד אסטרטגיות מסובכות וסכנות
                  </p>
                  <a
                    onClick={handleOpenContactDialog}
                    className="flex-inline items-center btn-link"
                  >
                    <span className="link-text text-lavender text text-base">
                      תצטרף עכשיו
                    </span>
                    <img src={arrow} className="link-icon" alt="link" />
                  </a>
                </div>
                <div className="info-item text-center">
                  <img src={createIcon} alt="" />
                  <h3 className="info-item-title">תקבל איתותים</h3>
                  <p className="text-base text info-item-text">
                    האנליסטים שלנו שולחים איתותים מדוייקים שהלקוחות שלנו יוכלו לסחור כמו וותיקים בשוק
                  </p>
                  <a
                    onClick={handleOpenContactDialog}
                    className="flex-inline items-center btn-link"
                  >
                    <span className="link-text text-lavender text text-base">
                      תתחיל לקבל איתותים
                    </span>
                    <img src={arrow} className="link-icon" />
                  </a>
                </div>
                <div className="info-item text-center">
                  <img src={manageIcon} alt="manage" />
                  <h3 className="info-item-title">תרוויח כסף</h3>
                  <p className="text-base text info-item-text">
                    תתחיל להרוויח כסף קל כמו שאר הלקוחות המרוצים שלנו
                  </p>
                  <a
                    onClick={handleOpenContactDialog}
                    className="flex-inline items-center btn-link"
                  >
                    <span className="link-text text-lavender text text-base">
                      תתחיל עכשיו
                    </span>
                    <img src={arrow} className="link-icon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end of header */}
        <main>
          <section className="page-sc-one flex items-center justify-center">
            <div className="container">
              <div className="sc-one-content text-center">
                <div className="title-wrapper">
                  <h2 className="large-title">
                    שני מסלולים שיכולים להבטיח לך הכנסה טובה וקלה
                  </h2>
                  <p className="text text-base">
                    שני המסלולים שלנו הותאמו לכל סוגי הלקוחות, כולם יכולים להרוויח בדרכים שונים
                  </p>
                  {/* <a
                    onClick={handleOpenContactDialog}
                    className="btn btn-base btn-g-blue-veronica text-base"
                  >
                    إنضم الآن
                  </a> */}
                </div>
              </div>
              <LandingPageAvailableSection handleOpenContactDialog={handleOpenContactDialog} />
            </div>
          </section>
          <section className="page-sc-two flex items-center">
            <div className="container">
              <div className="sc-two-content">
                <div className="sc-two-left">
                  <img src={supportIcon} />
                </div>
                <div className="sc-two-right">
                  <h2 className="large-title">
                    לשירות הלקוחות ולכל ייעוץ הצוות שלנו זמין 24/7
                  </h2>
                  <p className="text text-base">
                    כי הכסף שלכם הוא משאב מאוד חשוב לכם, וכדי שתוכלו להרגיש בנוח עם העסקות שאתם פותחים, אנחנו נהיה זמינים 24 שעות לכל ימי השבוע.
                  </p>
                  <a
                    onClick={handleOpenContactDialog}
                    className="btn btn-base btn-white text-base"
                  >
                    הצטרף למשפחה שלנו
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section className="page-sc-fees flex items-center">
            <div className="container">
              <div className="sc-fees-content text-center">
                <div className="title-wrapper">
                  <h2 className="large-title">
                    זה לא מסתכם רק בהבנה של הגרפים, אלא גם כולל ניתוח מעמיק שלהם
                  </h2>
                  <p className="text text-base">
                    יש לנו את הכלים הנדרשים כדי להבין את השוק, להתמודד עם תנודות בלתי צפויות ולהבטיח רווחים גבוהים יותר עם סיכון מופחת
                  </p>
                  <a
                    onClick={handleOpenContactDialog}
                    className="flex-inline items-center btn-link"
                  >
                    <span className="link-text text-lavender text text-base">
                      הצטרף עכשיו
                    </span>
                    <img src={arrow} className="link-icon" />
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section className="page-sc-invest flex items-center">
            <div className="container">
              <div className="sc-two-content">
                <div className="sc-two-left">
                  <h2 className="large-title">
                    התחל את דרכך לעבר השקעה יציבה ובטוחה בשוק המטבעות הקריפטוגרפיים.
                  </h2>
                  <p className="text text-base">
                    בוא להשקיע איתנו – המומחים לניתוח שוק והשקעות דיגיטליות. אנו נלווה אותך יד ביד עם ניתוחים מקצועיים וכלים מתקדמים, כדי להפוך את ההשקעות שלך למשתלמות ובטוחות
                  </p>
                  <a
                    onClick={handleOpenContactDialog}
                    className="btn btn-base btn-white text-base"
                  >
                    הצטרף עכשיו
                  </a>
                </div>
                <div className="sc-two-right">
                  <img src={supportIcon} />
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer className="footer">
          <div className="container">
            <div className="footer-content grid">
              <div className="footer-item">
                <p className="text text-base">
                  BitsBull.io - אנחנו נדאג לכסף שלך
                </p>
                <p className="text text-base">
                  הצטרף עכשיו וקבל איתותים מהחזקים ביותר
                </p>

                <p className="text text-base">
                  © Microspective Softwares {new Date().getFullYear()}
                </p>
              </div>
              {/* <div className="footer-item">
                <h4 className="footer-item-title text-gray text-base">
                  Privacy Policy and Terms of Services
                </h4>
                <ul className="footer-links">
                  <li>
                    <a className="footer-link text-gray text-base">
                      CoinFlip Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a className="footer-link text-gray text-base">
                      Biometrics Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a className="footer-link text-gray text-base">
                      Financial Policy Notice
                    </a>
                  </li>
                  <li>
                    <a className="footer-link text-gray text-base">
                      Terms of Service
                    </a>
                  </li>
                  <li>
                    <a className="footer-link text-gray text-base">
                      Trade Desk Terms of Service
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </footer>
      </div>
      {isContactDialogOpen && (
        <ContactFormDialog
          open={isContactDialogOpen}
          onClose={handleCloseContactDialog}
          maxWidth="sm"
        />
      )}
    </>
  );
};

export default LandingPage;
