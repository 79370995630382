import { Badge, Button, Chip, Grid, Stack, useTheme } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import ExcelJS from "exceljs";
import { isArray, uniqueId } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import excelLogo from "../../assets/icons/excel.svg";

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const headCells = (headers) => {
  const init = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "שם המשתמש",
    },
    {
      id: "id",
      numeric: true,
      disablePadding: false,
      label: "תז",
    },
    {
      id: "lastModified",
      numeric: true,
      disablePadding: false,
      label: "עדכון אחרון",
    },
    {
      id: "requiredDiamonds",
      numeric: true,
      disablePadding: false,
      label: "שווי ביהלומים",
    },
    {
      id: "moneyValue",
      numeric: true,
      disablePadding: false,
      label: "שווי בכסף",
    },
  ];

  if (headers) {
    return headers;
  }
  return init;
};

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableHeadCells,
    checkboxAvailable,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {checkboxAvailable && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
        )}
        {headCells(tableHeadCells).map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              fontWeight: 600,
              ...headCell.sx,
            }}
          >
            {headCell.sortable ? (
              <Tooltip
                title={
                  orderBy === headCell.id
                    ? order === "desc"
                      ? "יורד"
                      : "עולה"
                    : null
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  sx={{
                    paddingLeft: "4px",
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              </Tooltip>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  tableHeadCells: PropTypes.array,
  checkboxAvailable: PropTypes.bool,
};

function EnhancedTableToolbar(props) {
  const { numSelected, tableHeader } = props;
  const theme = useTheme();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: alpha(
            theme.palette.primary.main,
            theme.palette.action.activatedOpacity
          ),
        }),
      }}
    >
      {tableHeader && (
        <Stack
          sx={{ width: "100%" }}
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {tableHeader}
          </Typography>
        </Stack>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  tableHeader: PropTypes.any,
};

const createExcelFile = (rows, tableHeadCells, downlaodedFileName = "דוח") => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(
    `${downlaodedFileName}-${moment(new Date()).format("DD-MM-YYYY")}`
  );

  // Add headers to the worksheet
  const headers = tableHeadCells[0].label
    ? tableHeadCells.map((el) => el.label)
    : tableHeadCells || [
      "שם עובד",
      "ת.ז",
      "תאריך חתימה",
      "מס מזהה פנימי לחתימה",
    ];
  worksheet.addRow(headers);

  // Add data to the worksheet
  rows.forEach((row) => {
    // console.log(row);
    if (tableHeadCells) {
      // console.log(tableHeadCells);
      const rowValues = tableHeadCells.map(
        (el) =>
          `${row[el.id].prefix ? row[el.id].prefix : ""}${row[el.id]}${row[el.id].suffix ? row[el.id].suffix : ""
          }`
      );
      worksheet.addRow(rowValues);
    } else {
      const { name, id, signed_at, org_ref, post_id, user_id } = row;
      const signed_at_phrase =
        signed_at && signed_at.seconds
          ? moment(signed_at.seconds * 1000).format("DD-MM-YYYY HH:mm:ss")
          : "אין מידע";
      const internal_path_to_signature = `${org_ref}/$/${post_id}/$/${user_id}`;

      const values = [name, id, signed_at_phrase, internal_path_to_signature];
      worksheet.addRow(values);
    }
  });

  headers.forEach((header, index) => {
    const column = worksheet.getColumn(index + 1);
    column.width = header.length < 12 ? 12 : header.length;
  });

  // Generate the file blob
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a download link and trigger the click event
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${downlaodedFileName}-${moment(new Date()).format(
      "DD-MM-YYYY"
    )}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
  });
};

export default function DataTable({
  items,
  checkboxAvailable = false,
  tableHeadCells,
  tableHeader,
  rowOnClickCallback,
  downloadAction = true,
  downlaodedFileName,
}) {
  DataTable.propTypes = {
    items: PropTypes.array,
    checkboxAvailable: PropTypes.bool,
    tableHeadCells: PropTypes.any,
    tableHeader: PropTypes.any,
    rowOnClickCallback: PropTypes.func,
    downloadAction: PropTypes.bool,
    downlaodedFileName: PropTypes.string,
  };
  const theme = useTheme();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [rows, setRows] = useState([]);
  const [isLabelExpanded, setLabelExpanded] = useState([]);

  useEffect(() => {
    if (items) {
      setRows(items);
    }
  }, [items]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  const getFirstLabel = (data) => {
    // console.log(data);
    if (data.length > 1) {
      return { label: data[0].label, plus: data.length - 1 };
    }
    if (data.length === 1) {
      return { label: `${data[0].label}`, plus: 0 };
    }
    return { label: "--" };
  };

  const handleExpandChips = (rowId) => {
    const temp = [...isLabelExpanded];
    const selectedIndex = temp.indexOf(rowId);
    let newArr = [];

    if (selectedIndex === -1) {
      newArr = newArr.concat(temp, rowId);
    } else if (selectedIndex === 0) {
      newArr = newArr.concat(temp.slice(1));
    } else if (selectedIndex === temp.length - 1) {
      newArr = newArr.concat(temp.slice(0, -1));
    } else if (selectedIndex > 0) {
      newArr = newArr.concat(
        temp.slice(0, selectedIndex),
        temp.slice(selectedIndex + 1)
      );
    }

    setLabelExpanded(newArr);
  };

  return (
    <Grid item xs={12}>
      <Paper sx={{ width: "100%", mb: 2, position: "relative" }}>
        {visibleRows && visibleRows.length >= 0 && downloadAction && (
          <Button
            endIcon={
              <img
                width={22}
                height={22}
                loading="lazy"
                alt="excel icon"
                src={excelLogo}
              />
            }
            onClick={() =>
              createExcelFile(visibleRows, tableHeadCells, downlaodedFileName)
            }
            variant="outlined"
            color="success"
            sx={{
              position: "absolute",
              right: 0,
              transform: "translateY(calc(-100% - 6px))",
            }}
          >
            הורדת דוח
          </Button>
        )}
        {tableHeader && (
          <EnhancedTableToolbar
            tableHeader={tableHeader}
            numSelected={selected.length}
          />
        )}
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              tableHeadCells={tableHeadCells}
              checkboxAvailable={checkboxAvailable}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    role="row"
                    tabIndex={index + 1}
                    key={`${uniqueId("row")}-${row.id}`}
                    selected={isItemSelected}
                    hover
                    sx={{ cursor: rowOnClickCallback && "pointer" }}
                    onClick={() => {
                      if (rowOnClickCallback) {
                        rowOnClickCallback(row);
                      }
                    }}
                  >
                    {checkboxAvailable && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          onClick={(event) => handleClick(event, row.id)}
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                    )}
                    {headCells(tableHeadCells).map((headCell) => (
                      <TableCell
                        key={`${uniqueId("headcell")}-${headCell.id}`}
                        sx={{
                          ...headCell.sx,
                        }}
                        component="th"
                        id={`${labelId}-${headCell.id}`}
                        scope="row"
                        style={{
                          whiteSpace: "normal",
                          wordWrap: "break-word"
                        }}
                      >
                        {/* row[headCells[indexOfHead]] */}
                        {headCell.customComponent &&
                          React.isValidElement(headCell.customComponent) ? (
                          React.cloneElement(headCell.customComponent, {
                            ...row,
                            ...headCell.handlers,
                          })
                        ) : (
                          <>
                            {headCell.id && row[headCell.id] ? (
                              <>
                                {row[headCell.id].prefix &&
                                  row[headCell.id].prefix}
                                {headCell.date ? (
                                  <>
                                    {row[headCell.id] &&
                                      row[headCell.id].seconds
                                      ? moment(
                                        row[headCell.id].seconds * 1000
                                      ).format("DD/MM/YYYY")
                                      : "הרגע"}
                                  </>
                                ) : headCell.type === "chip" ? (
                                  isArray(row[headCell.id]) ? (
                                    <>
                                      {row[headCell.id].length > 0 && (
                                        <Stack
                                          key={`${uniqueId("stack-chips-")}-${row[headCell.id]
                                            }`}
                                          direction="row"
                                          gap={1}
                                        >
                                          {!isLabelExpanded.includes(row.id) ? (
                                            <Badge
                                              onClick={() => {
                                                // if(row[headCell.id].length > 1){
                                                // }
                                                handleExpandChips(row.id);
                                              }}
                                              badgeContent={
                                                getFirstLabel(row[headCell.id])
                                                  .plus &&
                                                `${getFirstLabel(
                                                  row[headCell.id]
                                                ).plus
                                                }+`
                                              }
                                              color="success"
                                            >
                                              <Chip
                                                sx={{
                                                  minWidth: 100,
                                                  "& .MuiChip-label": {
                                                    textOverflow:
                                                      !isLabelExpanded.includes(
                                                        row.id
                                                      ) && "ellipsis",
                                                    width: "100%",
                                                    overflow: "hidden",
                                                    whiteSpace:
                                                      !isLabelExpanded.includes(
                                                        row.id
                                                      )
                                                        ? "nowrap"
                                                        : "normal",
                                                    wordWrap: "break-word",
                                                    textAlign: "center",
                                                  },
                                                }}
                                                key={`${row.id}-chip-${uniqueId(
                                                  row[headCell.id]
                                                )}`}
                                                clickable
                                                onClick={() => {
                                                  // console.log(row[headCell.id]);
                                                  handleExpandChips(row.id);
                                                }}
                                                variant="filled"
                                                color="warning"
                                                {...row[headCell.id][0].props}
                                                label={
                                                  getFirstLabel(
                                                    row[headCell.id]
                                                  ).label
                                                }
                                              />
                                            </Badge>
                                          ) : (
                                            <>
                                              {row[headCell.id].map(
                                                (cellData) => (
                                                  <Chip
                                                    clickable
                                                    onClick={() => {
                                                      handleExpandChips(row.id);
                                                    }}
                                                    variant="filled"
                                                    color="warning"
                                                    {...cellData.props}
                                                    label={cellData.label}
                                                  />
                                                )
                                              )}
                                            </>
                                          )}
                                        </Stack>
                                      )}
                                      {row[headCell.id].length === 0 && "--"}
                                    </>
                                  ) : (
                                    <Chip
                                      sx={{
                                        minWidth: 70,
                                        backgroundColor: row.color || "#de3de3",
                                        color: theme.palette.getContrastText(
                                          row.color || "#de3de3"
                                        ),
                                      }}
                                      {...row[headCell.id].props}
                                      onClick={() => {
                                        handleExpandChips(row.id);
                                      }}
                                      label={row[headCell.id]}
                                    />
                                  )
                                ) : (
                                  row[headCell.id]
                                )}
                                {row[headCell.id].suffix &&
                                  row[headCell.id].suffix}
                              </>
                            ) : (
                              "אין מידע"
                            )}
                          </>
                        )}
                      </TableCell>
                    ))}
                    {/* <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row[headCells].name}
                    </TableCell>
                    <TableCell align="right">{row.id}</TableCell>

                    <TableCell align="right">
                      {row && row.last_modified
                        ? moment(row.last_modified.seconds * 1000).format(
                            'DD/MM/YYYY'
                          )
                        : 'אין מידע'}
                    </TableCell>
                    <TableCell align="right">{row.requiredDiamonds}</TableCell>
                    <TableCell align="right">{row.moneyValue}</TableCell>
                    <TableCell padding="normal">
                      <Chip label="אושרה" variant="outlined" color="success" />
                    </TableCell> */}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 25, 50]}
          component="div"
          labelRowsPerPage="מספר השורות לכל עמוד:"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Grid>
  );
}
