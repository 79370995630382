import {
  ContactsOutlined
} from "@mui/icons-material";
import LandingPage from "./LandingPage";
import ManageContacts from "./components/Contacts/ManageContacts";

const config = {
  application: {
    name: "BitsBull.io",
    logo: "/static/images/192.png",
    auth: {
      enableRegister: false,
      rolesAssigned: ["business"],
    },
    commonConfig: {
      showOfflineDialog: false,
    },
    theme: {
      mode: "light",
      primary: {
        main: "#232f3e",
      },
      secondary: {
        main: "#146eb4",
      },
    },
  },
  pages: [
    {
      path: "/",
      spec: {
        type: "Simple",
        object: <LandingPage />,
      },
    },
  ],
  dashboards: [
    {
      path: "/admin",
      name: {
        type: "Literal",
        text: "הנהלה",
      },
      roles: ["admin"],
      items: [
        // {
        //   path: "users",
        //   name: {
        //     type: "Literal",
        //     text: "مستخدمين",
        //   },
        //   icon: <PersonOutlineOutlined />,
        //   spec: {
        //     type: "Simple",
        //     object: <UsersList />,
        //   },
        // },
        {
          path: "contacts",
          name: {
            type: "Literal",
            text: "פניות",
          },
          icon: <ContactsOutlined />,
          spec: {
            type: "Simple",
            object: <ManageContacts />,
          },
        },
      ],
    },
    // {
    //   path: "/app",
    //   name: {
    //     type: "Literal",
    //     text: "مُتداوِلين",
    //   },
    //   roles: ["normal", "admin"],
    //   items: [
    //     {
    //       path: "guide",
    //       name: {
    //         type: "Literal",
    //         text: "دليل المُتداوِل",
    //       },
    //       icon: <ShowChartOutlined />,
    //       spec: {
    //         type: "Simple",
    //         object: <TradingGuide />,
    //       },
    //     },
    //   ],
    // },
  ],
  languages: {
    default: "he",
    list: [
      // {code: 'ar', name: 'العربية'},
      { code: "he", name: "עברית" },
      // {code: 'en', name: 'English'},
    ],
  },
};

export default config;
