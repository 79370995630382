import { initializeApp } from "firebase/app";
import {
  browserSessionPersistence,
  getAuth,
  indexedDBLocalPersistence,
  setPersistence,
} from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getFirestore,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { useMemo, useReducer } from "react";
import {
  useAuthState,
  useCreateUserWithEmailAndPassword,
  useSendPasswordResetEmail,
  useSignInWithEmailAndPassword,
} from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { getOrDefault } from "../../Helpers/Utils";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_APPLICATION":
      return {
        ...state,
        applicationMode: action.payload.applicationMode,
        app: action.payload.app,
      };
    case "SET_AUTH":
      return {
        ...state,
        auth: action.payload.auth,
        tenantId: action.payload.tenantId,
      };
    case "SET_FIRESTORE":
      return {
        ...state,
        firestore: action.payload.firestore,
      };
    case "SET_FIREBASE_FUNCTIONS":
      return {
        ...state,
        serverlessFunctions: action.payload.serverlessFunctions,
      };

    case "SET_FIREBASE_STORAGE":
      return {
        ...state,
        storage: action.payload.storage,
      };
    case "SET_USER_PROFILE":
      return {
        ...state,
        userProfile: action.payload.userDocument,
      };
    default:
      return state;
  }
};

const useFirebase = (config) => {
  const [properties, dispatch] = useReducer(reducer, {});

  useFirebaseApplication(properties, dispatch);
  useFirebaseAuthState(properties, dispatch);
  useFirebaseFirestoreProperties(properties, dispatch);
  useFirebaseFunctionsProperties(properties, dispatch);
  useFirebaseStorage(properties, dispatch);
  useUserProfile(properties, dispatch);

  useFirebaseSignInWithEmailAndPassword(properties);
  useFirebaseCreateUserWithEmailAndPassword(properties, config);
  useUnauthenticatedFirebaseSendPasswordResetLink(properties);
  useAuthenticatedFirebaseSendPasswordResetLink(properties);
  useDeleteUser(properties);
  useSubmitForm(properties, dispatch);
  useSignOut(properties);
  useCompleted(properties);
  return properties;
};

const useFirebaseApplication = (properties, dispatch) => {
  if (!properties.app) {
    const config = {};
    config.appId = process.env.REACT_APP_FIREBASE_APP_ID;
    config.apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
    config.authDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
    config.projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
    config.storageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
    config.messagingSenderId =
      process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
    config.measurmentId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

    const applicationMode = process.env.REACT_APP_MODE;
    const app = initializeApp(config);

    dispatch({
      type: "SET_APPLICATION",
      payload: {
        applicationMode,
        app,
      },
    });
  }
};

const useFirebaseAuthState = (properties, dispatch) => {
  if (!properties.auth) {
    const auth = getAuth(properties.app);
    const tenantId = process.env.REACT_APP_FIREBASE_AUTH_TENANT_ID;
    dispatch({ type: "SET_AUTH", payload: { auth, tenantId } });
  }
  const [user, loading, error] = useAuthState(
    properties.auth ? properties.auth : getAuth(properties.app)
  );
  properties.user = user;
  properties.loading = loading;
  properties.error = error;
};

const useFirebaseFirestoreProperties = (properties, dispatch) => {
  if (!properties.firestore) {
    const firestore = {};
    const instance = getFirestore(properties.app);
    firestore.instance = instance;
    firestore.user = {
      collectionName: "users",
      fields: {
        uid: "uid",
        firstName: "first_name",
        lastName: "last_name",
        roles: "roles",
      },
    };
    dispatch({ type: "SET_FIRESTORE", payload: { firestore } });
  }
};

const useFirebaseFunctionsProperties = (properties, dispatch) => {
  if (!properties.serverlessFunctions) {
    const instance = getFunctions(properties.app);
    // if (properties.applicationMode !== "PRODUCTION") {
    //   connectFunctionsEmulator(instance, "127.0.0.1", 5001);
    // }
    const serverlessFunctions = {
      instance,
      methods: {
        deleteUser: "deleteUser",
      },
    };
    dispatch({
      type: "SET_FIREBASE_FUNCTIONS",
      payload: { serverlessFunctions },
    });
  }
};
const useFirebaseStorage = (properties, dispatch) => {
  if (!properties.storage) {
    const instance = getStorage(properties.app);
    const storage = {
      instance,
    };
    dispatch({
      type: "SET_FIREBASE_STORAGE",
      payload: { storage },
    });
  }
};

const useUserProfile = (properties, dispatch) => {
  const { loading, user, firestore } = properties;
  useMemo(() => {
    if (!loading && firestore && user) {
      const docRef = doc(
        firestore.instance,
        firestore.user.collectionName,
        user.uid
      );
      getDoc(docRef).then((value) => {
        if (value.exists()) {
          const userDocument = getUserDocumentWithActualData(
            firestore,
            user,
            value.data()
          );
          if (userDocument) {
            dispatch({ type: "SET_USER_PROFILE", payload: { userDocument } });
          } else {
            dispatch({
              type: "SET_USER_PROFILE",
              payload: getUserDocumentWithInitialValue(),
            });
          }
        }
      });
    } else {
      dispatch({
        type: "SET_USER_PROFILE",
        payload: getUserDocumentWithInitialValue(),
      });
    }
  }, [loading, user, firestore, dispatch]);
};

const getUserDocumentWithActualData = (firestore, user, data) => {
  const uid = data[firestore.user.fields.uid];
  const firstName = data[firestore.user.fields.firstName];
  const lastName = data[firestore.user.fields.lastName];
  const displayName = [firstName, lastName].join(" ");
  const roles = data[firestore.user.fields.roles];
  const photoURL = user.photoURL;
  const profile = {
    completed: true,
    attributes: {
      uid,
      firstName,
      lastName,
      displayName,
      photoURL,
      roles,
    },
    methods: {
      updateUserProfile: (firstName, lastName) =>
        updateUserProfile(firestore, user, firstName, lastName),
    },
  };
  return profile;
};
const getUserDocumentWithInitialValue = () => {
  const initialValue = {
    completed: false,
    attributes: {
      uid: "",
      firstName: "",
      lastName: "",
      displayName: "",
      photoURL: "",
      roles: null,
    },
  };
  return initialValue;
};

const updateUserProfile = (firestore, user, firstName, lastName) => {
  const docRef = doc(
    firestore.instance,
    firestore.user.collectionName,
    user.uid
  );
  const userDocument = {};
  userDocument[firestore.user.fields.firstName] = firstName;
  userDocument[firestore.user.fields.lastName] = lastName;
  setDoc(docRef, userDocument, { merge: true })
    .then(() => {
      window.location.reload();
    })
    .catch(() => {
      console.error("Something Went Wrong.");
    });
};

const useSignOut = (properties) => {
  const navigate = useNavigate();
  const signOut = async (redirectTo) => {
    await properties.auth.signOut();
    const navigateTo = redirectTo
      ? "/login?redirectTo=" + redirectTo
      : "/login";
    navigate(navigateTo, { replace: true });
  };
  properties.signOut = signOut;
};

const useFirebaseSignInWithEmailAndPassword = (properties) => {
  const [signInWithEmailAndPassword] = useSignInWithEmailAndPassword(
    properties.auth
  );

  const doSignInWithEmailAndPassword = async (email, password, remember) => {
    return setPersistence(
      properties.auth,
      remember ? indexedDBLocalPersistence : browserSessionPersistence
    )
      .then(async () => {
        const val = await signInWithEmailAndPassword(email, password);
        return val !== undefined;
      })
      .catch((reason) => {
        console.error(reason);
        return false;
      });
  };
  properties.signInWithEmailAndPassword = doSignInWithEmailAndPassword;
};

const useFirebaseCreateUserWithEmailAndPassword = (properties, config) => {
  const [createUserWithEmailAndPassword] = useCreateUserWithEmailAndPassword(
    properties.auth,
    {
      sendEmailVerification: true,
    }
  );

  const enableRegister = getOrDefault(
    config.application.auth.enableRegister,
    true
  );
  const rolesAssigned = getOrDefault(config.application.auth.rolesAssigned, []);
  if (enableRegister) {
    const doCreateUserWithEmailAndPassword = async (userRegisterDetails) => {
      const email = userRegisterDetails.email;
      const password = userRegisterDetails.password;
      const firstName = userRegisterDetails.firstName;
      const lastName = userRegisterDetails.lastName;
      return await createUserWithEmailAndPassword(email, password).then(
        (value) => {
          const success = value !== undefined;
          if (success) {
            const userDocument = {};
            var roles = ["normal"];
            roles = roles.concat(rolesAssigned);
            userDocument[properties.firestore.user.fields.uid] = value.user.uid;
            userDocument[properties.firestore.user.fields.firstName] =
              firstName;
            userDocument[properties.firestore.user.fields.lastName] = lastName;
            userDocument[properties.firestore.user.fields.roles] = roles;
            setDoc(
              doc(
                properties.firestore.instance,
                properties.firestore.user.collectionName,
                value.user.uid
              ),
              userDocument
            );
          }
          return success;
        }
      );
    };
    properties.createUserWithEmailAndPassword =
      doCreateUserWithEmailAndPassword;
  } else {
    throw new Error("Register is not enabled.");
  }
};

const useSubmitForm = (properties, _) => {
  const submitFormWithPathAndValues = async (collectionPath, formValues) => {
    if (collectionPath && formValues) {
      // Remove leading slash if present
      if (collectionPath.startsWith("/")) {
        collectionPath = collectionPath.substring(1);
      }

      try {
        const pathSegments = collectionPath.split("/");
        if (pathSegments.length % 2 === 0) {
          const parentDocPath = pathSegments.slice(0, -1).join("/");
          const formId = pathSegments[pathSegments.length - 2];
          const parentDocRef = doc(
            properties.firestore.instance,
            parentDocPath
          );

          await setDoc(parentDocRef, { form_id: formId }, { merge: true });
        }

        const docRef = await addDoc(
          collection(properties.firestore.instance, collectionPath),
          { ...formValues, created_at: serverTimestamp() }
        );

        return docRef.id;
      } catch (error) {
        console.error("Error submitting form:", error);
        return null;
      }
    } else {
      throw new Error("Collection path and/or form values are required.");
    }
  };
  properties.submitFormWithPathAndValues = submitFormWithPathAndValues;
};

const useUnauthenticatedFirebaseSendPasswordResetLink = (
  properties,
  dispatch
) => {
  const [sendPasswordResetLink] = useSendPasswordResetEmail(properties.auth);

  const doSendPasswordResetLink = async (email) => {
    const result = await sendPasswordResetLink(email)
      .then((value) => value)
      .catch(() => {
        return false;
      });
    return result;
  };
  properties.unauthenticatedSendPasswordResetLink = doSendPasswordResetLink;
};

const useDeleteUser = (properties, dispatch) => {
  properties.deleteUser = async () => {
    const deleteUserFunction = httpsCallable(
      properties.serverlessFunctions.instance,
      properties.serverlessFunctions.methods.deleteUser
    );
    const result = await deleteUserFunction({
      collectionName: properties.firestore.user.collectionName,
      uid: properties.userProfile.attributes.uid,
    })
      .then((value) => {
        return value.data.statusCode === 202;
      })
      .catch(() => {
        return false;
      });
    return result;
  };
};

const useAuthenticatedFirebaseSendPasswordResetLink = (
  properties,
  dispatch
) => {
  const [sendPasswordResetLink] = useSendPasswordResetEmail(properties.auth);

  const doSendPasswordResetLink = async () => {
    const result = await sendPasswordResetLink(
      properties.auth.currentUser.email
    )
      .then((value) => value)
      .catch(() => {
        return false;
      });
    return result;
  };
  properties.sendPasswordResetLink = doSendPasswordResetLink;
};

const useCompleted = (properties) => {
  // dispatch({ type: "SET_COMPLETED", payload: { completed: true} })
  properties.completed = true;
};

export default useFirebase;
