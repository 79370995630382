import advanced from "./advanced.png";
import basic from "./basics.jpg";

const availableCourses = [
  {
    id: 1,
    name: "מסלול בסיסי",
    secondary: "קבלת איתותים בזמן אמת ועזרה בסיסית",
    lessons: [
      // {
      //   id: 1,
      //   name: "الدرس الأول",
      //   description:
      //     "العملات الرقمية سلسلة Blockchain, الشموع📊, الفيوتشر والسبوت, تحليل فني دعوم ومقاومة, موقع trading view وتعليم طرق أستعماله, الرافعة المالية",
      // },
      // {
      //   id: 2,
      //   name: "الدرس الثاني",
      //   description:
      //     "📈 السيولة الكسر الوهمي والكسر الحقيقي📊, مؤشرات وإدارة مخاطر",
      // },
      // {
      //   id: 3,
      //   name: "الدرس الثالث",
      //   description:
      //     "شرح عن الاخبار والاقتصاد لسوق المال🚨, تحليل لطالب (عملي), الطلبات والتنفيذ في منصات العملات الرقمية 💵",
      // },
    ],
    label: "בסיסי",
    img: basic,
  },
  {
    id: 2,
    name: "מסלול ליווי אישי",
    secondary: "ליווי אישי לכל מצטרף + איתותים VIP",
    lessons: [
      // {
      //   id: 1,
      //   name: "الدرس الأول",
      //   description:
      //     "العملات الرقمية هيكلية السوق, التصحيحات والتغير في هيكلة السوق",
      // },
      // {
      //   id: 2,
      //   name: "الدرس الثاني",
      //   description: "الأوردر بلوك, الفجوة السعرية ومناطق العرض والطلب",
      // },
      // {
      //   id: 3,
      //   name: "الدرس الثالث",
      //   description:
      //     "فيبوناتشي عادي /تعين اهداف, فيبوناتشي تعين مناطق الدخول, دمج استراتجية smc/sk , تطبيق عملي + تحليل لعملات على مبنى الاستراتجية كاملة",
      // },
    ],
    label: "מתקדם",
    img: advanced,
  },
];

export default availableCourses;
