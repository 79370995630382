import { Container } from "@mui/material";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useMicrospecDynamicUIContext } from "../../../MicrospecDynamicUI/GlobalProvider";
import DataTable from "../DataTable/DataTable";

const tableHeadCells = [
  {
    id: "full_name",
    numeric: false,
    disablePadding: false,
    label: "שם מלא",
    sortable: true,
  },
  {
    id: "user_phone",
    numeric: false,
    disablePadding: false,
    label: "מספר טלפון",
    sortable: true,
  },
  {
    id: "user_email",
    numeric: false,
    disablePadding: false,
    label: 'דוא״ל',
    sortable: true,
  },
  {
    id: "message",
    numeric: false,
    disablePadding: false,
    label: "הודעה",
    sortable: false
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "נוצר ב",
    date: true,
    sortable: true,
  },
];
const ManageContacts = () => {
  const { cloudProvider } = useMicrospecDynamicUIContext();
  const { firestore } = cloudProvider;
  const [contacts, setContacts] = useState();

  const retrieveFormContacts = async () => {
    const formRef = collection(
      firestore.instance,
      `forms/${process.env.REACT_APP_FORMID}/instances`
    );
    const q = query(formRef, orderBy("created_at", "desc"));
    const data = await getDocs(q).then((formInstances) => {
      if (formInstances.empty) {
        return [];
      }
      const contacts = formInstances.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      return contacts;
    });
    setContacts(data);
  };

  useEffect(() => {
    if (!contacts) {
      retrieveFormContacts();
    }
  }, [contacts]);

  return (
    <Container maxWidth="xl">
      <DataTable
        tableHeadCells={tableHeadCells}
        items={contacts}
        downlaodedFileName="bitsbull-form"
        downloadAction={Boolean(true)}
        checkboxAvailable={Boolean(false)}
        tableHeader="ניהול הפניות"
        key="contacts-management-dataTable"
      />
    </Container>
  );
};

export default ManageContacts;
