import { CloseOutlined } from "@mui/icons-material";
import {
  alpha,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import ContactForm from "../../../MicrospecDynamicUI/Pages/SectionsMapper/ContactForm";
import dialogBg from "../../assets/images/bg-part.png";
import contactFormSpec from "./contactFormSpec.js";

const ContactFormDialog = ({
  open,
  onClose,
  fullScreen,
  fullWidth,
  maxWidth,
}) => {
  ContactFormDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    fullScreen: PropTypes.bool,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  };
  const theme = useTheme();

  const handleSubmitContactForm = async (values) => {
    console.log(values);
  };

  return (
    <Dialog
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
      PaperProps={{
        className: "data-table-bg-color",
        sx: {
          backgroundImage: `linear-gradient(to top, ${alpha(
            theme.palette.grey[600],
            0.6
          )} 15%, ${alpha(theme.palette.grey[800], 0.65)}),
    url(${dialogBg})`,
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          color: "#d6d6d6",
        },
      }}
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="inherit" fontSize="1.5rem">
          תשאיר את הפרטים שלך, ואנחנו נחזור אליך
        </Typography>
        <IconButton
          sx={{
            color: "#d6d6d6c7",
          }}
          onClick={onClose}
        >
          <CloseOutlined />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ bgcolor: "#d6d6d638" }} />
      <DialogContent>
        <ContactForm
          spec={{
            ...contactFormSpec,
            cancelCallbackFunc: onClose,
            submitCallbackFunc: handleSubmitContactForm,
            formIdentifier: "bitsbull",
          }}
          type="ContactForm"
          index={1}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ContactFormDialog;
ContactFormDialog.defaultProps = {
  fullWidth: true,
  fullScreen: false,
  maxWidth: "sm",
};
