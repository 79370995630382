const contactFormSpec = {
  formIdentifier: "landing-page-inquiries",
  mainTitleText: " ",
  mainTitleColor: "",
  secondaryTitleText: " ",
  sectionBg: "rgba(0, 0, 0, 0)",
  secondaryTitleColor: "#ffffff",
  textBeforeSubmitButton: "השאר הודעה ואנחנו נחזור אליך",
  submitButtonColor: "#f2f2f2",
  submitButtonText: "שלח",
  clearButtonColor: "#f2f2f2",
  clearButtonText: "ביטול",
  successMessage: "תודה שיצרתם קשר",
  failureMessage:
    "לא הצלחנו לשלוח את ההודעה, בבקשה נסה שוב!",
  inputs: [
    {
      name: "full_name",
      type: "text",
      label: "*השם המלא",
      placeholder: "שם פרטי ושם משפחה",
      errorMessage: "בבקשה להכניס את השם המלא שלך",
      required: true,
    },
    {
      name: "user_email",
      type: "email",
      placeholder: "example@email.com",
      // errorMessage: "בבקשה להכניס את כתובת הדוא״ל שלך",
      label: "דוא״ל",
      required: false,
    },
    {
      name: "user_phone",
      type: "text",
      placeholder: "0520001234",
      errorMessage: "בבקשה להכניס את מספר הטלפון שלך",
      label: "*מספר הטלפון",
      required: true,
    },
    {
      name: "message",
      type: "textarea",
      placeholder: "בבקשה לכתוב הודעה",
      label: "ההודעה שלך",
      rows: 5,
      required: false,
    },
  ],
};

export default contactFormSpec;
