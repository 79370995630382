import { Toolbar } from "@mui/material";
import Box from "@mui/material/Box";
import { drawerWidth } from "./Dashboard";

const DashboardContent = ({ data }) => {
  const { item } = data;
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        width: {
          sm: `calc(100% - ${drawerWidth}px)`,
        },
      }}
    >
      <Toolbar />
      <Box
      // sx={{
      //   mt: APPBAR_MAX_HEIGHT,
      // }}
      >
        {item.element}
      </Box>
    </Box>
  );
};

export default DashboardContent;
